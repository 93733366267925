import request from '@/utils/request'
import requestForm from '@/utils/requestPic'


/**
 * 查询分站域名后缀
 * @param {*} data 
 * @returns 
 */
export function getDomainList(data) {
    return request({
        url: '/api/vip/domainList',
        method: 'post',
        params: data
    })
}



/**
 * 查询分站套餐列表
 * @param {*} data 
 * @returns 
 */
export function getMealList(data) {
    return request({
        url: '/api/vip/mealList',
        method: 'post',
        params: data
    })
}




/**
 * 查询主站商品列表
 * @param {*} data 
 * @returns 
 */
export function queryMainProductList(data) {
    return request({
        url: '/api/fz/queryMainProductList',
        method: 'post',
        data
    })
}



/**
 * 查询分站自己的商品列表
 * @param {*} data 
 * @returns 
 */
export function queryProductList(data) {
    return request({
        url: '/api/vip/product/queryProductList',
        method: 'post',
        data
    })
}



/**
 * 同步主站商品
 * @param {*} data 
 * @returns 
 */
export function syncMainProduct(data) {
    return request({
        url: '/api/fz/syncMainProduct',
        method: 'post',
        data
    })
}

/**
 * 修改商品
 * @param {*} data 
 * @returns 
 */
export function updateProduct(data) {
    return request({
        url: '/api/fz/updateProduct',
        method: 'post',
        data
    })
}


/**
 * 下架商品
 * @param {*} data 
 * @returns 
 */
export function deleteProduct(data) {
    return request({
        url: '/api/fz/deleteProduct',
        method: 'post',
        data
    })
}


/**
 * 批量改价
 * @param {*} data 
 * @returns 
 */
export function batchProductPrice(data) {
    return request({
        url: '/api/fz/batchProductPrice',
        method: 'post',
        data
    })
}


/**
 * 开通分站
 * @param {*} data 
 * @returns 
 */
export function createFz(data) {
    return request({
        url: '/api/vip/createFz',
        method: 'post',
        data
    })
}



/**
 * 保存分站商城基本信息
 * @param {*} data 
 * @returns 
 */
export function saveBasicInfo(data) {
    return request({
        url: '/api/vip/saveBasicInfo',
        method: 'post',
        data
    })
}


/**
 * 获取商城基本信息
 * @param {*} data 
 * @returns 
 */
export function getBasicInfo(data) {
    return request({
        url: '/api/vip/getBasicInfo',
        method: 'post',
        data
    })
}


/**
 * 分站站长获取用户订单
 * @param {*} data 
 * @returns 
 */
export function queryUserOrder(data) {
    return request({
        url: '/api/vip/order/queryOrderList',
        method: 'post',
        data
    })
}


// 分站图片上传
export function uploadPic(data) {
    return requestForm({
        url: '/api/upload/public_pic',
        method: 'post',
        data
    })
}



/**
 * 获取分站用户信息
 * @param {*} data 
 * @returns 
 */
export function getUserList(data) {
    return request({
        url: '/api/vip/user/list',
        method: 'post',
        data
    })
}


// 修改商品价格
export function updateVipProductPrice(data) {
    return request({
        url: '/api/vip/product/updateVipProductPrice',
        method: 'post',
        data
    })
}

// 一键设置未设置加价商品
export function allProductMarkupUp(data) {
    return request({
        url: '/api/vip/product/allProductMarkupUp',
        method: 'post',
        data
    })
}


// 一键删除加价商品
export function allProductCleanMarkupUp(data) {
    return request({
        url: '/api/vip/product/allProductCleanMarkupUp',
        method: 'post',
        data
    })
}


// 修改商品状态
export function updateVipProductStatus(data) {
    return request({
        url: '/api/vip/product/updateVipProductStatus',
        method: 'post',
        data
    })
}


// 批量修改商品状态
export function batchUpdateVipProductStatus(data) {
    return request({
        url: '/api/vip/product/batchUpdateVipProductStatus',
        method: 'post',
        data
    })
}


// 获取分站地址
export function getVipAddress(data) {
    return request({
        url: '/api/vip/getVipAddress',
        method: 'post',
        data
    })
}


/**
 * 查询分站客服信息
 * @param {*} data 
 * @returns 
 */
export function getCustomerDetail(data) {
    return request({
        url: '/api/vip/customer/detail',
        method: 'post',
        data
    })
}


/**
 * 新增分站客服信息
 * @param {*} data 
 * @returns 
 */
export function customerSave(data) {
    return request({
        url: '/api/vip/customer/save',
        method: 'post',
        data
    })
}


// 获取分站弹框公告
export function getIndexNotice() {
    return request({
        url: '/api/vip/setting/getIndexNotice',
        method: 'post'
    })
}


// 新增分站弹框公告
export function saveIndexNotice(data) {
    console.log(data)
    return request({
        url: '/api/vip/setting/saveIndexNotice',
        method: 'post',
        data: data
    })
}



// 查询分站商品统计
export function queryProductStatis(data) {
    return request({
        url: '/api/vip/statis/queryProductStatis',
        method: 'post',
        data
    })
}

// 查询分站订单统计
export function queryOrderStatis(data) {
    return request({
        url: '/api/vip/statis/queryOrderStatis',
        method: 'post',
        data
    })
}

// 查询分站首页统计
export function queryIndexStatis(data) {
    return request({
        url: '/api/vip/statis/queryIndexStatis',
        method: 'post',
        data
    })
}

// ===========================banner================================




// 查询轮播图列表
export function getBannerList(query) {
    return request({
        url: '/api/vip/banner/list',
        method: 'get',
        params: query
    })
}

// 查询轮播图详细
export function getBanner(id) {
    return request({
        url: '/api/vip/banner/' + id,
        method: 'get'
    })
}

// 新增轮播图
export function addBanner(data) {
    return request({
        url: '/api/vip/banner/add',
        method: 'post',
        data
    })
}



// 修改轮播图
export function updateBanner(data) {
    return request({
        url: '/api/vip/banner/edit',
        method: 'put',
        data: data
    })
}

// 删除轮播图
export function delBanner(id) {
    return request({
        url: '/api/vip/banner/' + id,
        method: 'delete'
    })
}




/**
 * 查询分站下级分站
 * @param {*} data 
 * @returns 
 */
export function queryVipList(data) {
    return request({
        url: '/api/vip/list/queryList',
        method: 'post',
        data
    })
}